const seaFreightSchema = {
    page: {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "Organization",
                "@id": "https://postkodes.com/#organization",
                "url": "https://postkodes.com/",
                "name": "Postkodes",
                "sameAs": [
                    "https://www.facebook.com/getaddressdynamic/",
                    "https://www.instagram.com/postkodes/"
                ]
            },
            {
                "@type": "WebSite",
                "@id": "https://postkodes.com/#website",
                "url": "https://postkodes.com/",
                "name": "Postkodes",
                "publisher": {
                    "@id": "https://postkodes.com/#organization"
                }
            },
            {
                "@type": "WebPage",
                "@id": "https://postkodes.com/#webpage",
                "url": "https://postkodes.com/sea-freight-services/",
                "inLanguage": "en-US",
                "name": "Sea Freight Services - Shipment & Delivery | Postkodes",
                "isPartOf": {
                    "@id": "https://postkodes.com/#website"
                },
                "datePublished": "2024-03-14T00:00:00-08:00",
                "dateModified": "2024-03-14T00:00:00-08:00",
                "description": "Postkodes offers reliable sea freight services with efficient shipping solutions worldwide. Trust us for secure and timely delivery of your goods by sea."
            }
        ]
    },

    service: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Sea Freight Services - Shipment & Delivery | Postkodes",
        "provider": {
            "@type": "Organization",
            "name": "Postkodes",
            "url": "https://postkodes.com/"
        },
        "description": "Postkodes offers reliable sea freight services with efficient shipping solutions worldwide. Trust us for secure and timely delivery of your goods by sea.",
        "url": "https://postkodes.com/sea-freight-services/",
        "mainEntityOfPage": "https://postkodes.com/",
        "areaServed": "Global",
        "serviceType": [
            "Sea Freight Services",
            "Sea Freight Logistics",
            "Sea Freight Company",
            "Sea Freight Container Tracking",
            "Sea Freight Rates Per Kg"
        ],
        "sameAs": [
            "https://www.facebook.com/getaddressdynamic/",
            "https://www.instagram.com/postkodes/"
        ]
    },

    breadcrumb: {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "item": {
                    "@id": "https://postkodes.com/",
                    "name": "Home"
                }
            },
            {
                "@type": "ListItem",
                "position": 2,
                "item": {
                    "@id": "https://postkodes.com/sea-freight-services/",
                    "name": "Sea Freight Services"
                }
            }
        ]
    },

    faq: {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            {
                "@type": "Question",
                "name": "What types of sea freight services does Postkodes offer?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Postkodes is a leading sea freight company providing a wide range of services, including Full Container Load (FCL), Less than Container Load (LCL), and specialized cargo handling. We cater to various shipping needs, whether you're transporting small shipments or large volumes of goods globally."
                }
            },
            {
                "@type": "Question",
                "name": "How are sea freight rates per kg calculated?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our sea freight rates per kg are determined based on factors such as the weight and volume of the cargo, cargo type, origin and destination, the type of goods being shipped, and the chosen service level. For an accurate estimate, you can use our online quote tool or contact our team for a personalized quote."
                }
            },
            {
                "@type": "Question",
                "name": "Can I track my sea freight container in real-time?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, Postkodes offers advanced sea freight container tracking. Our system allows you to monitor your shipment in real-time, providing updates on its location and status throughout the shipping process. You can track your container directly through our website."
                }
            },
            {
                "@type": "Question",
                "name": "How long does sea freight shipping take?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The transit time for sea freight depends on the origin and destination of your shipment, as well as the specific service used. Generally, sea freight can take anywhere from a few weeks to several months. For more precise timings, our logistics experts can provide details based on your shipping requirements."
                }
            },
            {
                "@type": "Question",
                "name": "What documents are required for sea freight shipping?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Key documents typically required for sea freight shipping include the Bill of Lading, Commercial Invoice, Packing List, Certificate of Origin, and any specific permits or licenses depending on the goods and destination country. Our team at Postkodes will guide you through the necessary documentation to ensure a smooth shipping process."
                }
            },
            {
                "@type": "Question",
                "name": "How do I get started with Postkodes' sea freight services?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Getting started with Postkodes is easy. You can request a quote directly through our website or contact our customer service team for a consultation. We'll help you choose the right sea freight logistics service and provide you with all the necessary information to initiate your shipment."
                }
            },
            {
                "@type": "Question",
                "name": "What is the difference between FCL and LCL in sea freight?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "FCL (Full Container Load) means your goods are transported in a dedicated container, while LCL (Less than Container Load) means your cargo is combined with other shipments in a shared container. FCL is ideal for larger shipments, while LCL is cost-effective for smaller loads. Postkodes offers both options based on your needs."
                }
            },
            {
                "@type": "Question",
                "name": "Are there any restrictions on what can be shipped via sea freight?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, certain items may be restricted or require special handling when shipped via sea freight, such as hazardous materials, perishable goods, and oversized cargo. Postkodes adheres to international shipping regulations and will advise you on any specific restrictions related to your shipment."
                }
            },
            {
                "@type": "Question",
                "name": "How do I ensure the safety of my goods during sea freight shipping?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "At Postkodes, we prioritize the safety of your cargo. We offer various packaging and loading solutions to protect your goods, along with comprehensive insurance options. Our expert team will also provide guidance on best practices for securing your shipment."
                }
            }
        ]
    }
};

export default seaFreightSchema; 