import React, { Component } from 'react'
import './../HomePage.css'
import './Freight.css'
import airFreightBg from './../../assets/slide-3.webp'
import airFreightImg from './../../assets/airfreight.webp'
import PartnersAndTestimonialSection from './../components/PartnersAndTestimonialsSection'
import Footer from './../components/Footer'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Navbars from '../../Navbar/Navbar'
import SpecialServicesCarousel from '../components/SpecialServicesCarousel'
import AppSection from '../components/AppSection'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import airFreightSchema from '../schema/airFreightSchema'

export default class AirFreight extends Component {
    render() {
        const slides = [
            { src: airFreightBg, alt: 'First slide' },
        ]
        return (
            <>
                <Helmet>
                    <title>Air Freight Services - Shipment & Delivery | Postkodes</title>
                    <meta name="description" content="Postkodes offers reliable and efficient air freight services, ensuring fast delivery and secure handling for all your global shipping needs." />
                    <meta name="keywords" content="air freight services, air freight shipping, international air freight, air cargo international shipping, Air Freight" />
                    <script type="application/ld+json">
                        {JSON.stringify(airFreightSchema.page)}
                    </script>
                    <script type="application/ld+json">
                        {JSON.stringify(airFreightSchema.service)}
                    </script>
                    <script type="application/ld+json">
                        {JSON.stringify(airFreightSchema.breadcrumb)}
                    </script>
                    <script type="application/ld+json">
                        {JSON.stringify(airFreightSchema.faq)}
                    </script>
                </Helmet>
                <div className='home-page-container'>
                    <div className="register-track-section">
                        <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                {slides.map((slide, index) => (
                                    <div key={`Home-${slide.alt}`} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                        <img className="d-block w-100 freight-bg-style img-fluid" src={slide.src} alt={slide.alt} />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="freight-layer">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Navbars />
                                    </div>
                                    <div className="col-12">
                                        <div className='freight-banner-content d-flex justify-content-center'>
                                            <h1>POSTKODES: Premier Air Freight Services</h1>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className='freight-description-section'>
                        <div class="container my-5">
                            <div class="row">
                                <div class="text-left freight-intro col-md-8">
                                    <p>At POSTKODES, we specialize in providing top-tier air freight services, ensuring your cargo reaches its international destination swiftly and securely. Our comprehensive air freight solutions are designed to meet the demands of global trade, offering speed, reliability, and efficiency in air cargo international shipping.</p>
                                    <h2 class="mb-5">
                                        Our Air Freight Services Include:
                                    </h2>
                                    <ul>
                                        <li>
                                            <h3>Express Air Freight Shipping</h3> <p>Ideal for urgent shipments that require the fastest delivery times, ensuring your goods arrive on schedule, no matter the distance.</p>
                                        </li>
                                        <li>
                                            <h3>Standard Air Freight:</h3> <p>Offering a balance of speed and cost-effectiveness, our standard service is perfect for regular international air freight, providing reliable delivery within optimal transit times.</p>
                                        </li>
                                        <li>
                                            <h3>Door-to-Door Air Freight:</h3> <p>We manage every step of the process, from picking up your cargo at the origin to delivering it directly to its final destination, providing a seamless end-to-end solution.</p>
                                        </li>
                                        <li>
                                            <h3>Customs Clearance:</h3><p> Our experienced team handles all customs formalities, ensuring smooth clearance processes for your international air freight shipments, minimizing delays and avoiding complications.</p>
                                        </li>
                                        <li>
                                            <h3>Specialized Air Cargo:</h3> <p> Whether you're shipping perishable goods, oversized cargo, or hazardous materials, we offer specialized services tailored to meet the unique requirements of your cargo.</p>
                                        </li>
                                        <li>
                                            <h3>Real-Time Tracking:</h3> <p> Stay informed with our advanced tracking system, giving you real-time updates on your shipment's status from departure to arrival.</p>
                                        </li>
                                    </ul>
                                </div>
                                <img className="mx-md-0 mx-3" width={300} height={200} src={airFreightImg} alt="airFreightImg" />
                            </div>

                            <div class="mb-5 freight-benefits">
                                <h2 class="mb-3">Why Choose Postkodes for Air Freight Shipping?</h2>
                                <p>With POSTKODES, you can trust that your air freight shipping needs are in expert hands. Whether you're shipping across continents or within tight deadlines, our air freight services are designed to deliver excellence, ensuring your cargo reaches its destination swiftly and securely</p>
                                <ul class="">
                                    <li class="mb-2"><strong>Global Network:</strong><br /> With a vast network of carriers and partners across the globe, we ensure your cargo can be shipped to virtually any destination, offering unmatched reach in air cargo international shipping.</li>
                                    <li class="mb-2"><strong>Expertise & Experience:<br /></strong>  Our team of air freight specialists brings years of experience to the table, offering personalized solutions that cater to your specific shipping needs.</li>
                                    <li class="mb-2"><strong>Fast & Reliable:<br /></strong>We prioritize speed and reliability, ensuring that your shipments arrive on time, every time, with our efficient air freight services.</li>
                                    <li class="mb-2"><strong>Competitive Pricing:<br /></strong> We offer competitive rates without compromising on quality, providing cost-effective solutions for your international air freight requirements.</li>
                                    <li class="mb-2"><strong>Safety & Security:<br /></strong> We take every precaution to ensure the safety and security of your cargo, employing rigorous handling procedures and working with trusted carriers.</li>
                                </ul>
                                <p>Choose POSTKODES for your sea freight needs and experience the difference that comes with expertise, reliability, and personalized service. Let us be your trusted partner in navigating the complexities of global trade.</p>
                            </div>
                        </div>
                    </section>

                    <section className='faq-section p-0'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2>Frequently Asked Questions</h2>
                                    <div id="accordion">
                                        <div class="card">
                                            <div class="card-header" id="headingOne">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        What is air freight shipping, and how does it work?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>

                                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                <div class="card-body">
                                                    Air freight shipping involves transporting goods via airplanes. It's ideal for time-sensitive shipments and provides fast delivery. At Postkodes, we handle everything from pickup to delivery, ensuring your cargo reaches its destination safely and on time.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        What types of goods can be shipped via air freight?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                <div class="card-body">
                                                    Postkodes can handle a wide range of goods, including electronics, perishables, pharmaceuticals, and more, ensuring they meet air transport regulations.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="headingThree">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        How does Postkodes ensure the security of my cargo?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div class="card-body">
                                                    We use state-of-the-art security measures, including secure packaging, tamper-proof seals, and GPS tracking to ensure your cargo is safe from pickup to delivery.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading5">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                                        What is the typical delivery time for international air freight?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
                                                <div class="card-body">
                                                    Delivery times vary based on the destination, but air freight is the fastest shipping method, typically ranging from 1 to 7 days.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading6">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                                        Can I track my shipment in real-time?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                                                <div class="card-body">
                                                    Yes, Postkodes provides real-time tracking so you can monitor your shipment's progress at any time.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading7">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                                        How do I get a quote for my air freight shipment?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordion">
                                                <div class="card-body">
                                                    You can easily get a quote by filling out our online form or contacting our customer service team directly.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading8">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                                        How do you ensure the safety of air cargo during international shipping?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse8" class="collapse" aria-labelledby="heading8" data-parent="#accordion">
                                                <div class="card-body">
                                                    At Postkodes, we adhere to international shipping standards and regulations. We also use advanced tracking systems to monitor your cargo in real-time, ensuring it is secure throughout the journey.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading9">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                                        How fast can I expect delivery with Postkodes air freight services?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse9" class="collapse" aria-labelledby="heading9" data-parent="#accordion">
                                                <div class="card-body">
                                                    Delivery times vary depending on the destination and service chosen. However, air freight is one of the fastest shipping methods available, with many shipments delivered within 1-3 business days.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <SpecialServicesCarousel />

                    <section className="reach-destination-section">
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <h2>Innovative Technology</h2>
                                    <p>Leveraging the latest technologies, we continuously innovate to stay ahead of the curve and provide our clients with state-of-the-art logistics solutions.</p>
                                </div>
                                <div className='col-md-4 d-flex align-items-center justify-content-centerd'>
                                    <Link to='/verifyEmail'><button className="btn btn-danger">Register Now &rarr;</button></Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <PartnersAndTestimonialSection />

                    <AppSection />

                </div >
                <Footer />
            </>
        )
    }
}   
