const errandsSchema = {
    page: {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "Organization",
                "@id": "https://postkodes.com/#organization",
                "url": "https://postkodes.com/",
                "name": "Postkodes",
                "sameAs": [
                    "https://www.facebook.com/getaddressdynamic/",
                    "https://www.instagram.com/postkodes/"
                ]
            },
            {
                "@type": "WebSite",
                "@id": "https://postkodes.com/#website",
                "url": "https://postkodes.com/",
                "name": "Postkodes",
                "publisher": {
                    "@id": "https://postkodes.com/#organization"
                }
            },
            {
                "@type": "WebPage",
                "@id": "https://postkodes.com/errands-services",
                "url": "https://postkodes.com/errands-services",
                "inLanguage": "en-US",
                "name": "Errands Services- Shipment & Delivery | Postkodes",
                "isPartOf": {
                    "@id": "https://postkodes.com/#website"
                },
                "datePublished": "2024-03-14T00:00:00-08:00",
                "dateModified": "2024-03-14T00:00:00-08:00",
                "description": "Postkodes offers reliable and efficient errands services to handle your daily tasks. Trust us to simplify your life and manage your to-do list with ease."
            }
        ]
    },

    service: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Errands Services- Shipment & Delivery | Postkodes",
        "provider": {
            "@type": "Organization",
            "name": "Postkodes",
            "url": "https://postkodes.com/"
        },
        "description": "Postkodes offers reliable and efficient errands services to handle your daily tasks. Trust us to simplify your life and manage your to-do list with ease.",
        "url": "https://postkodes.com/errands-services/",
        "mainEntityOfPage": "https://postkodes.com/",
        "areaServed": "Global",
        "serviceType": [
            "Delivery and Errand Services Business",
            "Errand Service Sydney",
            "Errand and Delivery Service",
            "Errand Delivery Services",
            "Errands Services",
            "Errand Service"
        ],
        "sameAs": [
            "https://www.facebook.com/getaddressdynamic/",
            "https://www.instagram.com/postkodes/"
        ]
    },

    breadcrumb: {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "item": {
                    "@id": "https://postkodes.com/",
                    "name": "Home"
                }
            },
            {
                "@type": "ListItem",
                "position": 2,
                "item": {
                    "@id": "https://postkodes.com/errands-services/",
                    "name": "Errands Services"
                }
            }
        ]
    },

    faq: {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            {
                "@type": "Question",
                "name": "What is an errand delivery service?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "An errand delivery service, like Postkodes in Sydney, helps individuals by completing tasks such as grocery shopping, prescription pick-ups, and parcel deliveries. Our service is designed to save you time and make life easier."
                }
            },
            {
                "@type": "Question",
                "name": "How do I book an errand and delivery service with Postkodes in Sydney?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Booking our errand and delivery service is simple. You can place your order online through our website or by calling our team directly. We serve all areas within Sydney, NSW."
                }
            },
            {
                "@type": "Question",
                "name": "What areas in Sydney do you cover with your errands services?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Postkodes offers comprehensive errands services throughout Sydney, NSW. No matter where you are located, our team is ready to assist with your delivery needs."
                }
            },
            {
                "@type": "Question",
                "name": "How much does your errand service cost in Sydney?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The cost of our errand service in Sydney varies depending on the type of errand and the distance involved. We offer competitive rates and will provide a clear quote before you confirm your booking."
                }
            },
            {
                "@type": "Question",
                "name": "Is there a minimum order requirement for your errand and delivery service?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "No, there is no minimum order requirement for our errand and delivery service. Whether you have a small task or a larger errand, Postkodes is here to help."
                }
            },
            {
                "@type": "Question",
                "name": "How fast can you complete an errand service in Sydney?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We aim to complete most errands services on the same day. Delivery times can vary depending on the specific task and location within Sydney, NSW."
                }
            },
            {
                "@type": "Question",
                "name": "What safety measures do you take during your errands services?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "At Postkodes, we prioritize the safety and security of your items during all our errands services. Our delivery personnel are trained to handle your packages with care, and we adhere to all recommended health and safety guidelines."
                }
            }
        ]
    }
};

export default errandsSchema; 