const airFreightSchema = {
    page: {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "Organization",
                "@id": "https://postkodes.com/#organization",
                "url": "https://postkodes.com/",
                "name": "Postkodes",
                "sameAs": [
                    "https://www.facebook.com/getaddressdynamic/",
                    "https://www.instagram.com/postkodes/"
                ]
            },
            {
                "@type": "WebSite",
                "@id": "https://postkodes.com/#website",
                "url": "https://postkodes.com/",
                "name": "Postkodes",
                "publisher": {
                    "@id": "https://postkodes.com/#organization"
                }
            },
            {
                "@type": "WebPage",
                "@id": "https://postkodes.com/air-freight-services/",
                "url": "https://postkodes.com/air-freight-services/",
                "inLanguage": "en-US",
                "name": "Air Freight Services - Shipment & Delivery | Postkodes",
                "isPartOf": {
                    "@id": "https://postkodes.com/#website"
                },
                "datePublished": "2024-03-14T00:00:00-08:00",
                "dateModified": "2024-03-14T00:00:00-08:00",
                "description": "Postkodes offers reliable and efficient air freight services, ensuring fast delivery and secure handling for all your global shipping needs."
            }
        ]
    },

    service: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Air Freight Services - Shipment & Delivery | Postkodes",
        "provider": {
            "@type": "Organization",
            "name": "Postkodes",
            "url": "https://postkodes.com/"
        },
        "description": "Postkodes offers reliable and efficient air freight services, ensuring fast delivery and secure handling for all your global shipping needs.",
        "url": "https://postkodes.com/air-freight-services/",
        "mainEntityOfPage": "https://postkodes.com/",
        "areaServed": "Global",
        "serviceType": [
            "Air Freight Services",
            "Air Freight Shipping",
            "International Air Freight",
            "Parcel Delivery Service",
            "Air Cargo International Shipping"
        ],
        "sameAs": [
            "https://www.facebook.com/getaddressdynamic/",
            "https://www.instagram.com/postkodes/"
        ]
    },

    breadcrumb: {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "item": {
                    "@id": "https://postkodes.com/",
                    "name": "Home"
                }
            },
            {
                "@type": "ListItem",
                "position": 2,
                "item": {
                    "@id": "https://postkodes.com/air-freight-services",
                    "name": "Air Freight Services"
                }
            }
        ]
    },

    faq: {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            {
                "@type": "Question",
                "name": "What is air freight shipping, and how does it work?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Air freight shipping involves transporting goods via airplanes. It's ideal for time-sensitive shipments and provides fast delivery. At Postkodes, we handle everything from pickup to delivery, ensuring your cargo reaches its destination safely and on time."
                }
            },
            {
                "@type": "Question",
                "name": "What types of goods can be shipped via air freight?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Postkodes can handle a wide range of goods, including electronics, perishables, pharmaceuticals, and more, ensuring they meet air transport regulations."
                }
            },
            {
                "@type": "Question",
                "name": "How does Postkodes ensure the security of my cargo?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We use state-of-the-art security measures, including secure packaging, tamper-proof seals, and GPS tracking to ensure your cargo is safe from pickup to delivery."
                }
            },
            {
                "@type": "Question",
                "name": "What is the typical delivery time for international air freight?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Delivery times vary based on the destination, but air freight is the fastest shipping method, typically ranging from 1 to 7 days."
                }
            },
            {
                "@type": "Question",
                "name": "Can I track my shipment in real-time?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, Postkodes provides real-time tracking so you can monitor your shipment's progress at any time."
                }
            },
            {
                "@type": "Question",
                "name": "How do I get a quote for my air freight shipment?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You can easily get a quote by filling out our online form or contacting our customer service team directly."
                }
            },
            {
                "@type": "Question",
                "name": "How do you ensure the safety of air cargo during international shipping?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "At Postkodes, we adhere to international shipping standards and regulations. We also use advanced tracking systems to monitor your cargo in real-time, ensuring it is secure throughout the journey."
                }
            },
            {
                "@type": "Question",
                "name": "How fast can I expect delivery with Postkodes air freight services?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Delivery times vary depending on the destination and service chosen. However, air freight is one of the fastest shipping methods available, with many shipments delivered within 1-3 business days."
                }
            }
        ]
    }
};

export default airFreightSchema; 