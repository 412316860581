import React, { Component } from 'react'
import './../HomePage.css'
import './Freight.css'
import errandsBg from './../../assets/errands-bg.webp'
import errandsImg from './../../assets/errands.webp'
import PartnersAndTestimonialSection from './../components/PartnersAndTestimonialsSection'
import Footer from './../components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbars from '../../Navbar/Navbar';
import SpecialServicesCarousel from '../components/SpecialServicesCarousel'
import AppSection from '../components/AppSection'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import errandsSchema from '../schema/errandsSchema';


export default class Errands extends Component {
    render() {
        const slides = [
            { src: errandsBg, alt: 'First slide' },
        ]
        return (
            <>
                <Helmet>
                    <title>Errands Services- Shipment & Delivery | Postkodes</title>
                    <meta name="description" content="Postkodes offers reliable and efficient errands services to handle your daily tasks. Trust us to simplify your life and manage your to-do list with ease." />
                    <meta name="keywords" content="delivery and errand services business, errand service sydney, errand delivery service, errand and delivery service, errands services, errand service" />
                    <script type="application/ld+json">
                        {JSON.stringify(errandsSchema.page)}
                    </script>
                    <script type="application/ld+json">
                        {JSON.stringify(errandsSchema.service)}
                    </script>
                    <script type="application/ld+json">
                        {JSON.stringify(errandsSchema.breadcrumb)}
                    </script>
                    <script type="application/ld+json">
                        {JSON.stringify(errandsSchema.faq)}
                    </script>
                </Helmet>
                <div className='home-page-container'>
                    <div className="register-track-section">
                        <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                {slides.map((slide, index) => (
                                    <div key={`Home-${slide.alt}`} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                        <img className="d-block w-100 freight-bg-style img-fluid" src={slide.src} alt={slide.alt} />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="freight-layer">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Navbars />
                                    </div>
                                    <div className="col-12">
                                        <div className='freight-banner-content d-flex justify-content-center'>
                                            <h1>Effortless Errands Services Right to Your Doorstep with Postkodes</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className='freight-description-section'>
                        <div class="container my-5">
                            <div class="row">
                                <div class="text-left freight-intro col-md-8">
                                    <p>POSTKODES Delivery Service is a premier errand and delivery service dedicated to simplifying your life by taking care of your day-to-day tasks. Whether it's picking up groceries, delivering parcels, or running miscellaneous errands, POSTKODES is here to help. We understand that time is a valuable commodity, and our mission is to give you back more of it by handling your errands efficiently and reliably.</p>
                                    <h2 class="mb-5">
                                        We Offer Comprehensive Errands and Delivery Services:
                                    </h2>
                                    <ul>
                                        <li>
                                            <h3>Grocery Shopping & Delivery</h3> <p>We shop for and deliver groceries right to your doorstep. Send us your list, and we'll take care of the rest.</p>
                                        </li>
                                        <li>
                                            <h3>Parcel Pickup & Drop-off:</h3> <p>Whether it's sending packages or receiving deliveries, we ensure your parcels are picked up and delivered promptly.</p>
                                        </li>
                                        <li>
                                            <h3>Prescription & Pharmacy Runs:</h3> <p>We manage your prescription pickups and deliver them securely to your home.</p>
                                        </li>
                                        <li>
                                            <h3>Dry Cleaning & Laundry:</h3><p> Let us handle your laundry and dry cleaning pickups and drop-offs, saving you the hassle.</p>
                                        </li>
                                        <li>
                                            <h3>Personal Shopping:</h3> <p> Need something specific from a store? We'll get it for you and bring it directly to you.</p>
                                        </li>
                                        <li>
                                            <h3>Miscellaneous Errands:</h3> <p> From picking up documents to delivering gifts, no errand is too small or too big for us.</p>
                                        </li>
                                    </ul>
                                </div>
                                <img className="mx-md-0 mx-3" width={300} height={200} src={errandsImg} alt="errandsImg" />
                            </div>

                            <div class="mb-5 freight-benefits">
                                <p><strong>Target Market: </strong>POSTKODES caters to busy professionals, elderly individuals, new parents, and anyone who values their time and seeks a hassle-free solution for everyday tasks. Our services are ideal for people who want to optimize their day by outsourcing routine errands.</p>
                                <h2 class="mb-3">Business Values:</h2>
                                <ul class="">
                                    <li class="mb-2"><strong>Reliability:</strong><br /> We pride ourselves on being dependable and ensuring that your errands are completed as requested, on time, every time.</li>
                                    <li class="mb-2"><strong>Efficiency:<br /></strong>  We streamline the errand process to save you as much time as possible, allowing you to focus on what matters most.</li>
                                    <li class="mb-2"><strong>Customer-Centric Approach:<br /></strong>Our service is tailored to meet the unique needs of each customer. We listen, adapt, and deliver personalized solutions.</li>
                                    <li class="mb-2"><strong>Transparency:<br /></strong> Clear communication and upfront pricing are central to our business model. You'll always know what to expect, with no hidden fees.</li>
                                </ul>
                                <p><strong>Competitive Advantage: </strong>POSTKODES distinguishes itself through a combination of speed, convenience, and a highly personalized service model. Our user-friendly app allows customers to book, track, and manage their errands effortlessly, while our customer support team is always available to assist.</p>
                                <p><strong>Location: </strong>Based in Sydney, NSW, POSTKODES serves clients across the Sydney and greater Sydney area. We are continually expanding our service reach to meet growing demand.</p>
                                <p><strong>Technology & Innovation: </strong>Our mobile app and website offer an easy-to-use platform for customers to request services, make payments, and track deliveries in real-time. With secure payment options and automated reminders, managing your errands has never been simpler.</p>
                                <p><strong>Vision Statement: </strong>To be the leading errand delivery service, trusted by customers for our reliability, efficiency, and commitment to enhancing their quality of life.</p>
                                <p><strong>Mission Statement: </strong>Our mission is to simplify the lives of our customers by providing a seamless, efficient, and dependable errand delivery service, allowing them to focus on what truly matters.</p>
                            </div>
                        </div>
                    </section>

                    <section className='faq-section p-0'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2>Frequently Asked Questions</h2>
                                    <div id="accordion">
                                        <div class="card">
                                            <div class="card-header" id="headingOne">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        What is an errand delivery service?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>

                                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                <div class="card-body">
                                                    An errand delivery service, like Postkodes in Sydney, helps individuals by completing tasks such as grocery shopping, prescription pick-ups, and parcel deliveries. Our service is designed to save you time and make life easier.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        How do I book an errand and delivery service with Postkodes in Sydney?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                <div class="card-body">
                                                    Booking our errand and delivery service is simple. You can place your order online through our website or by calling our team directly. We serve all areas within Sydney, NSW.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="headingThree">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        What areas in Sydney do you cover with your errands services?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div class="card-body">
                                                    Postkodes offers comprehensive errands services throughout Sydney, NSW. No matter where you are located, our team is ready to assist with your delivery needs.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading5">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                                        How much does your errand service cost in Sydney?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
                                                <div class="card-body">
                                                    The cost of our errand service in Sydney varies depending on the type of errand and the distance involved. We offer competitive rates and will provide a clear quote before you confirm your booking.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading6">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                                        Is there a minimum order requirement for your errand and delivery service?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                                                <div class="card-body">
                                                    No, there is no minimum order requirement for our errand and delivery service. Whether you have a small task or a larger errand, Postkodes is here to help.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading7">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                                        How fast can you complete an errand service in Sydney?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordion">
                                                <div class="card-body">
                                                    We aim to complete most errands services on the same day. Delivery times can vary depending on the specific task and location within Sydney, NSW.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading8">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                                        What safety measures do you take during your errands services?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse8" class="collapse" aria-labelledby="heading8" data-parent="#accordion">
                                                <div class="card-body">
                                                    At Postkodes, we prioritize the safety and security of your items during all our errands services. Our delivery personnel are trained to handle your packages with care, and we adhere to all recommended health and safety guidelines.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <SpecialServicesCarousel />

                    <section className="reach-destination-section">
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <h2>Innovative Technology</h2>
                                    <p>Leveraging the latest technologies, we continuously innovate to stay ahead of the curve and provide our clients with state-of-the-art logistics solutions.</p>
                                </div>
                                <div className='col-md-4 d-flex align-items-center justify-content-centerd'>
                                    <Link to='/verifyEmail'><button className="btn btn-danger">Register Now &rarr;</button></Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <PartnersAndTestimonialSection />

                    <AppSection />

                </div >
                <Footer />
            </>
        )
    }
}   
