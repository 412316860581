const roadFreightSchema = {
    page: {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "Organization",
                "@id": "https://postkodes.com/#organization",
                "url": "https://postkodes.com/",
                "name": "Postkodes",
                "sameAs": [
                    "https://www.facebook.com/getaddressdynamic/",
                    "https://www.instagram.com/postkodes/"
                ]
            },
            {
                "@type": "WebSite",
                "@id": "https://postkodes.com/#website",
                "url": "https://postkodes.com/",
                "name": "Postkodes",
                "publisher": {
                    "@id": "https://postkodes.com/#organization"
                }
            },
            {
                "@type": "WebPage",
                "@id": "https://postkodes.com/road-freight-services",
                "url": "https://postkodes.com/road-freight-services",
                "inLanguage": "en-US",
                "name": "Road Freight Services - Shipment & Delivery | Postkodes",
                "isPartOf": {
                    "@id": "https://postkodes.com/#website"
                },
                "datePublished": "2024-03-14T00:00:00-08:00",
                "dateModified": "2024-03-14T00:00:00-08:00",
                "description": "Postkodes offers top-quality road freight services with reliable delivery and competitive pricing. Trust us to deliver your goods safely and on time."
            }
        ]
    },

    service: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Road Freight Services - Shipment & Delivery | Postkodes",
        "provider": {
            "@type": "Organization",
            "name": "Postkodes",
            "url": "https://postkodes.com/"
        },
        "description": "Postkodes offers top-quality road freight services with reliable delivery and competitive pricing. Trust us to deliver your goods safely and on time.",
        "url": "https://postkodes.com/road-freight-services/",
        "mainEntityOfPage": "https://postkodes.com/",
        "areaServed": "Global",
        "serviceType": [
            "Road Freight Services",
            "Road Freight Shipping",
            "Road Freight Transport",
            "Road Freight Logistics",
            "Road Freight Tracking"
        ],
        "sameAs": [
            "https://www.facebook.com/getaddressdynamic/",
            "https://www.instagram.com/postkodes/"
        ]
    },

    breadcrumb: {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "item": {
                    "@id": "https://postkodes.com/",
                    "name": "Home"
                }
            },
            {
                "@type": "ListItem",
                "position": 2,
                "item": {
                    "@id": "https://postkodes.com/road-freight-services/",
                    "name": "Road Freight Services"
                }
            }
        ]
    },

    faq: {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            {
                "@type": "Question",
                "name": "What are road freight services?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Road freight services, offered by Postkodes, refer to the transportation of goods via road using trucks or other vehicles. It is a cost-effective and efficient method for transporting goods across short and long distances."
                }
            },
            {
                "@type": "Question",
                "name": "How does road freight shipping work?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Road freight shipping with Postkodes involves loading goods onto trucks, which then transport them to their destination via road networks. The process includes pickup, transportation, and delivery, often with real-time tracking available through our systems."
                }
            },
            {
                "@type": "Question",
                "name": "What are the benefits of road freight transport?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Road freight transport offers flexibility, door-to-door delivery, and cost-effectiveness. Postkodes ensures that it's ideal for both small parcels and large shipments, with the ability to reach remote areas."
                }
            },
            {
                "@type": "Question",
                "name": "How can I track my road freight shipment?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "At Postkodes, we provide a road freight tracking system that allows you to monitor your shipment in real-time. You'll receive updates and notifications about the status of your delivery."
                }
            },
            {
                "@type": "Question",
                "name": "What types of goods can be transported by road freight?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Almost any type of goods can be transported by road freight, including perishable items, industrial equipment, consumer goods, and more. Postkodes offers services that are customizable to meet the specific needs of your shipment."
                }
            },
            {
                "@type": "Question",
                "name": "How do I choose the right road freight logistics provider?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "When selecting a road freight logistics provider, consider their experience, network coverage, technology for tracking, and ability to offer customized solutions. Postkodes excels in all these areas, making us a reliable choice for your freight needs."
                }
            }
        ]
    }
};

export default roadFreightSchema; 