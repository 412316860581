const serviceSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Global Parcel Tracking - Shipment & Delivery | Postkodes",
    "provider": {
        "@type": "Organization",
        "name": "Postkodes",
        "url": "https://postkodes.com/"
    },
    "description": "Need cheap international shipping? Postkodes provides global parcel tracking, pickup services, and worldwide delivery solutions at great prices.",
    "url": "https://postkodes.com/",
    "mainEntityOfPage": "https://postkodes.com/",
    "areaServed": "Global",
    "serviceType": [
        "Parcel Pickup Service",
        "Global Parcel Tracking",
        "Worldwide Courier Tracking",
        "Parcel Delivery Service",
        "Cheap International Shipping"
    ],
    "sameAs": [
        "https://www.facebook.com/getaddressdynamic/",
        "https://www.instagram.com/postkodes/"
    ]
};

export default serviceSchema;
