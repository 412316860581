import React, { Component } from 'react'
import './../HomePage.css'
import './Freight.css'
import seaFreightBg from './../../assets/slide-2.webp'
import seaFreightImg from './../../assets/slide-1.webp'
import PartnersAndTestimonialSection from './../components/PartnersAndTestimonialsSection'
import Footer from './../components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbars from '../../Navbar/Navbar';
import SpecialServicesCarousel from '../components/SpecialServicesCarousel'
import AppSection from '../components/AppSection'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import seaFreightSchema from '../schema/seaFreightSchema';


export default class SeaFreight extends Component {
    render() {
        const slides = [
            { src: seaFreightBg, alt: 'First slide' },
        ]
        return (
            <>
                <Helmet>
                    <title>Sea Freight Services - Shipment & Delivery | Postkodes</title>
                    <meta name="description" content="Postkodes offers reliable sea freight services with efficient shipping solutions worldwide. Trust us for secure and timely delivery of your goods by sea." />
                    <meta name="keywords" content="sea freight services, sea freight logistics, sea freight company, sea freight container tracking, sea freight rates per kg" />
                    <script type="application/ld+json">
                        {JSON.stringify(seaFreightSchema.page)}
                    </script>
                    <script type="application/ld+json">
                        {JSON.stringify(seaFreightSchema.service)}
                    </script>
                    <script type="application/ld+json">
                        {JSON.stringify(seaFreightSchema.breadcrumb)}
                    </script>
                    <script type="application/ld+json">
                        {JSON.stringify(seaFreightSchema.faq)}
                    </script>
                </Helmet>
                <div className='home-page-container'>
                    <div className="register-track-section">
                        <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                {slides.map((slide, index) => (
                                    <div key={`Home-${slide.alt}`} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                        <img className="d-block w-100 freight-bg-style img-fluid" src={slide.src} alt={slide.alt} />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="freight-layer">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Navbars />
                                    </div>
                                    <div className="col-12">
                                        <div className='freight-banner-content d-flex justify-content-center'>
                                            <h1>POSTKODES: Reliable Sea Freight Services</h1>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className='freight-description-section'>
                        <div class="container my-5">
                            <div class="row">
                                <div class="text-left freight-intro col-md-8">
                                    <p>At POSTKODES, we offer comprehensive sea freight solutions designed to meet your global shipping needs with efficiency and reliability. Whether you're shipping full containers (FCL) or less-than-container loads (LCL), our extensive network and industry expertise ensure that your cargo reaches its destination safely and on time.</p>
                                    <h2 class="mb-5">
                                        Our Sea Freight Services Include:
                                    </h2>
                                    <ul>
                                        <li>
                                            <h3>Full Container Load (FCL):</h3> <p>Ideal for large shipments, providing exclusive use of a container, ensuring security and cost-effectiveness for bulk cargo.</p>
                                        </li>
                                        <li>
                                            <h3>Less-than-Container Load (LCL):</h3> <p>Perfect for smaller shipments, allowing you to share container space with others, optimizing costs while maintaining delivery schedules.</p>
                                        </li>
                                        <li>
                                            <h3>Customs Clearance & Documentation:</h3> <p>We handle all the necessary paperwork and customs formalities, ensuring smooth and hassle-free clearance at both origin and destination ports.</p>
                                        </li>
                                        <li>
                                            <h3>Door-to-Door Delivery:</h3><p> From port to your doorstep, our end-to-end service covers the entire journey of your cargo, giving you peace of mind.</p>
                                        </li>
                                        <li>
                                            <h3>Cargo Insurance:</h3> <p> We offer comprehensive insurance options to protect your valuable goods against unforeseen risks during transit.</p>
                                        </li>
                                        <li>
                                            <h3>Tracking & Monitoring:</h3> <p> Stay informed with real-time sea freight container tracking of your shipments, providing transparency and control over your sea freight logistics.</p>
                                        </li>
                                    </ul>
                                </div>
                                <img className="mx-md-0 mx-3" width={300} height={200} src={seaFreightImg} alt="seaFreightImg" />
                            </div>

                            <div class="mb-5 freight-benefits">
                                <h2 class="mb-3">Why Choose Postkodes as a Sea Freight Company?</h2>
                                <p>By choosing POSTKODES, you're opting for a streamlined, transparent, and efficient sea freight process, backed by a team of experts dedicated to your success. From the moment you request a quote to the final delivery of your goods, we're here to ensure that your sea freight experience is smooth, reliable, and worry-free.</p>
                                <ul class="">
                                    <li class="mb-2"><strong>Global Network:</strong><br /> With partners in key ports worldwide, we facilitate seamless international trade, no matter where your business takes you.</li>
                                    <li class="mb-2"><strong>Expertise & Experience:<br /></strong>  Our seasoned professionals understand the complexities of sea freight, offering tailored solutions that cater to your specific requirements.</li>
                                    <li class="mb-2"><strong>Cost-Effective Solutions:<br /></strong>We prioritize efficiency, ensuring that our services are not only reliable but also competitively priced to give you the best value.</li>
                                    <li class="mb-2"><strong>Sustainability Commitment:<br /></strong> We are committed to reducing our carbon footprint by working with eco-friendly carriers and optimizing our routes for lower emissions.</li>
                                </ul>
                                <p>Choose POSTKODES for your sea freight needs and experience the difference that comes with expertise, reliability, and personalized service. Let us be your trusted partner in navigating the complexities of global trade.</p>
                            </div>

                            <div class="mb-5">
                                <h2 class="h2 mb-3">How Our Sea Freight Company Works</h2>
                                <p>At POSTKODES, we streamline the sea freight process to ensure a seamless experience from start to finish. Here's how it works:</p>
                                <div className="row ml-2">
                                    <ol className="col-md-12 mx-md-0 mx-3">
                                        <li className="mb-2">
                                            <strong>Request a Quote</strong>
                                            <ul>
                                                <li>
                                                    <strong>Initial Consultation:</strong> Contact us with your shipment details, including cargo type, weight, dimensions, and destination.
                                                </li>
                                                <li>
                                                    <strong>Customized Quote:</strong> We provide a tailored quote based on your specific needs, offering competitive rates and transit times including an option for sea freight rates per kg.
                                                </li>
                                            </ul>

                                        </li>
                                        <li className="mb-2">
                                            <strong>Booking & Confirmation</strong>
                                            <ul>
                                                <li>
                                                    <strong>Booking Your Shipment:</strong> Once you approve the quote, we'll proceed with booking your shipment with the most reliable carriers.
                                                </li>
                                                <li>
                                                    <strong>Documentation</strong> Our team will assist in preparing all necessary shipping documents, including the bill of lading, customs forms, and any special permits required.
                                                </li>
                                            </ul>

                                        </li>
                                        <li className="mb-2">
                                            <strong>Cargo Preparation & Pickup</strong>
                                            <ul>
                                                <li>
                                                    <strong>Cargo Inspection:</strong> We coordinate with your supplier to ensure that your cargo is properly packaged and ready for transport.
                                                </li>
                                                <li>
                                                    <strong>Pickup & Transport:</strong>  Our sea freight services team arranges for the pickup of your goods from your location or supplier and transports them to the port of departure.
                                                </li>
                                            </ul>

                                        </li>

                                        <li className="mb-2">
                                            <strong>Customs Clearance</strong>
                                            <ul>
                                                <li><strong>Export Clearance:</strong> We handle all export customs formalities, ensuring your cargo meets all regulatory requirements before leaving the country.</li>
                                                <li><strong>Import Clearance:</strong> On arrival at the destination port, our team manages the import clearance process, minimizing delays and ensuring smooth entry.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-2">
                                            <strong>Sea Freight Transit</strong>
                                            <ul>
                                                <li><strong>Loading & Shipping:</strong> Your cargo is loaded onto the vessel and begins its journey across the ocean. We work with trusted carriers to ensure timely and secure transport.</li>
                                                <li><strong>Real-Time Tracking:</strong> Monitor your shipment in real-time with our advanced tracking system, keeping you informed at every stage of the journey.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-2">
                                            <strong>Arrival & Delivery</strong>
                                            <ul>
                                                <li><strong>Port Arrival:</strong> Upon arrival at the destination port, we oversee the unloading of your cargo and conduct a thorough inspection to ensure everything is intact.</li>
                                                <li><strong>Final Delivery:</strong> Depending on your needs, we arrange for door-to-door delivery, transporting your goods from the port to their final destination.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-2">
                                            <strong>Post-Delivery Support</strong>
                                            <ul>
                                                <li><strong>Documentation Handover:</strong> We provide you with all final shipping documents and receipts, completing the transaction.</li>
                                                <li><strong>Ongoin Support:</strong> Our customer service team is available for any post-delivery inquiries or assistance you may need.</li>
                                            </ul>
                                        </li>


                                    </ol>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className='faq-section p-0'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2>Frequently Asked Questions</h2>
                                    <div id="accordion">
                                        <div class="card">
                                            <div class="card-header" id="headingOne">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        What types of sea freight services does Postkodes offer?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>

                                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                <div class="card-body">
                                                    Postkodes is a leading sea freight company providing a wide range of services, including Full Container Load (FCL), Less than Container Load (LCL), and specialized cargo handling. We cater to various shipping needs, whether you're transporting small shipments or large volumes of goods globally.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        How are sea freight rates per kg calculated?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                <div class="card-body">
                                                    Our sea freight rates per kg are determined based on factors such as the weight and volume of the cargo, cargo type, origin and destination, the type of goods being shipped, and the chosen service level. For an accurate estimate, you can use our online quote tool or contact our team for a personalized quote.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="headingThree">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Can I track my sea freight container in real-time?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div class="card-body">
                                                    Yes, Postkodes offers advanced sea freight container tracking. Our system allows you to monitor your shipment in real-time, providing updates on its location and status throughout the shipping process. You can track your container directly through our website.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading5">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                                        How long does sea freight shipping take?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
                                                <div class="card-body">
                                                    The transit time for sea freight depends on the origin and destination of your shipment, as well as the specific service used. Generally, sea freight can take anywhere from a few weeks to several months. For more precise timings, our logistics experts can provide details based on your shipping requirements.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading6">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                                        What documents are required for sea freight shipping?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                                                <div class="card-body">
                                                    Key documents typically required for sea freight shipping include the Bill of Lading, Commercial Invoice, Packing List, Certificate of Origin, and any specific permits or licenses depending on the goods and destination country. Our team at Postkodes will guide you through the necessary documentation to ensure a smooth shipping process.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading7">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                                        How do I get started with Postkodes' sea freight services?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordion">
                                                <div class="card-body">
                                                    Getting started with Postkodes is easy. You can request a quote directly through our website or contact our customer service team for a consultation. We'll help you choose the right sea freight logistics service and provide you with all the necessary information to initiate your shipment.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading8">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                                        What is the difference between FCL and LCL in sea freight?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse8" class="collapse" aria-labelledby="heading8" data-parent="#accordion">
                                                <div class="card-body">
                                                    FCL (Full Container Load) means your goods are transported in a dedicated container, while LCL (Less than Container Load) means your cargo is combined with other shipments in a shared container. FCL is ideal for larger shipments, while LCL is cost-effective for smaller loads. Postkodes offers both options based on your needs.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading9">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                                        Are there any restrictions on what can be shipped via sea freight?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse9" class="collapse" aria-labelledby="heading9" data-parent="#accordion">
                                                <div class="card-body">
                                                    Yes, certain items may be restricted or require special handling when shipped via sea freight, such as hazardous materials, perishable goods, and oversized cargo. Postkodes adheres to international shipping regulations and will advise you on any specific restrictions related to your shipment.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading10">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                                        How do I ensure the safety of my goods during sea freight shipping?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse10" class="collapse" aria-labelledby="heading10" data-parent="#accordion">
                                                <div class="card-body">
                                                    At Postkodes, we prioritize the safety of your cargo. We offer various packaging and loading solutions to protect your goods, along with comprehensive insurance options. Our expert team will also provide guidance on best practices for securing your shipment.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <SpecialServicesCarousel />

                    <section className="reach-destination-section">
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <h2>Innovative Technology</h2>
                                    <p>Leveraging the latest technologies, we continuously innovate to stay ahead of the curve and provide our clients with state-of-the-art logistics solutions.</p>
                                </div>
                                <div className='col-md-4 d-flex align-items-center justify-content-centerd'>
                                    <Link to='/verifyEmail'><button className="btn btn-danger">Register Now &rarr;</button></Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <PartnersAndTestimonialSection />

                    <AppSection />

                </div >
                <Footer />
            </>
        )
    }
}   
